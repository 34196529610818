<template>
    <section class="acces">
        <div class="acces-intro">
            <div class="acces-intro__container">
                <p>J'ai le plaisir de vous accueillir dans un cadre chaleureux dans le 1èr arrondissement de Paris, près de l'avenue de l'Opera dans l'institut Le Nouveau Monde Paris.</p>
                <div class="acces-intro__owner">
                    <img src="@/assets/img/manuela.jpg" alt="Manuela Cardos">
                    <h5>Manuela</h5>
                </div>
            </div>
            <div class="acces-icons">
                <a href="https://goo.gl/maps/4UrBUbincr7Fk3un6" class="icon-container">
                    <img src="@/assets/icons/location-accent.png" class="icon" alt="Adresse">
                    <div class="icon-text">
                        <h6>Adresse</h6>
                        <p>{{contactDetails.address}}</p>
                    </div>
                </a>
                <a href="mailto:contact@manuelanutritionparis.com" class="icon-container">
                    <img src="@/assets/icons/mail-accent.png" class="icon" alt="Mail">
                    <div class="icon-text">
                        <h6>Mail</h6>
                        <p>{{contactDetails.mail}}</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="acces-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.594961810419!2d2.3311698156746505!3d48.86593357928822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66fb795136fbd%3A0x7c553176d469a355!2sManuela%20Cardos%20Naturopathe!5e0!3m2!1sfr!2sfr!4v1668718763426!5m2!1sfr!2sfr" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MapComponent',

    setup() {
        let contactDetails = {
            address : `21 rue d'Argenteuil, 75001 Paris`,
            phone : '+33188615773',
            mail : 'contact@manuelanutritionparis.com',
        }

        return { contactDetails }
    }
}
</script>

<style lang="scss" scoped>

.acces-intro {
    .acces-intro__container {
        padding: 1em;
        @media(min-width: 640px) {
            width: 60%;
            margin: auto;
        }
        p {
            font-size: clamp(1.2rem, 3vw, 1.4rem);
            margin-bottom: 5em;
        }
        .acces-intro__owner {
            text-align: center;
            img {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                margin-bottom: 1em;
            }
            h5 {
                font-family: WestivaMedium;
                font-size: clamp(1.5rem, 3vw, 2rem);
            }
        }
    }
    .acces-icons {        
        display: flex;
        flex-flow: row wrap;
        margin-block: 4em;

        > * {
            flex: 1 0 15em;
        }
        .icon-container {
            display: flex;
            margin: 1em;
            position: relative;
            
            overflow: hidden;

            .icon {
                width: 50px;
                height: 50px;
                // border-radius: 50%;
                padding: .5em;
                margin-right: 1.5em;
            }
            // img[alt="Adresse"] {
            //     background-color: #6D59EE;
            // }
            // img[alt="Telephone"] {
            //     background-color: #55D576;
            // }
            // img[alt="Mail"] {
            //     background-color: #028FFD;
            // }

            .icon-text {
                h6 {
                    color: #C4743E;
                    font-family: WestivaMedium;
                    font-size: 1.5rem;
                    margin-bottom: .6em;
                }
                p{
                    font-size: .8rem;
                }
            }
        }

        .icon-container:nth-of-type(2) {
            &::before,
            &::after {
                background-color: #FF9A8B;
                background-image: linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%);

            }
        }
        .icon-container:nth-of-type(3) {
            &::before,
            &::after {
                background-color: #A9C9FF;
                background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);
            }
        }
        @media(min-width:640px) {
            display: flex;
            justify-content: space-evenly;
            width: 90%;
            margin-inline: auto;
            margin-bottom: 3em;
        }
    }
}
.acces-map {
    height: 350px;
    iframe {
        width: 100%;
        height: 100%;
        filter: grayscale(.5);
    }
}
</style>