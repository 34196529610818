<template>
    <nav>
        <div class="logo">
            <router-link :to="{ name: 'Accueil' }">
                <picture>
                    <source srcset="@/assets/logo-top.png" media="(min-width: 640px)">
                    <img src="@/assets/logo-small.png" alt="Manuela Nutrition">
                </picture>
            </router-link>
        </div>

        <ul v-show="!mobile" class="nav-links">
            <li><router-link :to="{ name: 'Accueil' }" class="nav-link">Accueil</router-link></li>
            <li><router-link :to="{ name: 'Apropos' }" class="nav-link spacing">Àpropos</router-link></li>
            <li><router-link :to="{ name: 'Detox' }" class="nav-link">Détox</router-link></li>
            <li><router-link :to="{ name: 'Naturopathie' }" class="nav-link">Naturopathie</router-link></li>
            <li><router-link :to="{ name: 'Nutrition' }" class="nav-link">Nutrition</router-link></li>
            <li><router-link :to="{ name: 'Minceur' }" class="nav-link">Minceur</router-link></li>
            <li><router-link :to="{ name: 'Soins' }" class="nav-link">Soins</router-link></li>
        </ul>

        <transition name="mobile-nav">
            <ul v-show="mobileNav" class="nav-links-active">
                <li><router-link :to="{ name: 'Accueil' }" class="nav-link">Accueil</router-link></li>
                <li><router-link :to="{ name: 'Apropos' }" class="nav-link">À propos</router-link></li>
                <li><router-link :to="{ name: 'Detox' }" class="nav-link">Détox</router-link></li>
                <li><router-link :to="{ name: 'Naturopathie' }" class="nav-link">Naturopathie</router-link></li>
                <li><router-link :to="{ name: 'Nutrition' }" class="nav-link">Nutrition</router-link></li>
                <li><router-link :to="{ name: 'Minceur' }" class="nav-link">Minceur</router-link></li>
                <li><router-link :to="{ name: 'Soins' }" class="nav-link">Soins</router-link></li>
            </ul>
        </transition>

        <div v-show="mobile" class="menu" :class="{'menu-active' : mobileNav}" @click="toggleMobileNav">
            <div class="top-line"></div>
            <div class="bottom-line"></div>
        </div>

        <a href="https://www.planity.com/manuela-cardos-naturopathe-et-masseuse-bien-etre-75001-paris" class="booking-btn">
            réservation
        </a>
    </nav>
</template>

<script>
export default {
    name: 'NavbarComponent',
    data() {
        return {
            mobile: null,
            mobileNav: null,
            windowWidth: null,
        }
    },
    created() {
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    },
    methods: {
        toggleMobileNav() {
            this.mobileNav = !this.mobileNav;
        },
        checkScreen() {
            this.windowWidth = window.innerWidth;
            if(this.windowWidth <= 1064){
                this.mobile = true;
                return
            }
            this.mobile = false;
            this.mobileNav = false;
            return
        }
    }
}
</script>

<style lang="scss" scoped>
    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .5em;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;

        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        background-color: rgba(146, 146, 146, 0.5);

        @media (min-width: 640px) {
            padding-inline: 2em;
        }

        .nav-links {
            position: absolute;
            inset: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100vh;
            width: 100%;
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            background-color: rgba(146, 146, 146, 0.5);
            z-index: 2;
            clip-path: circle(0% at 100% 0%);
            transition: .3s ease;

            @media(min-width:950px) {
                clip-path: unset;
                backdrop-filter: blur(0);
                -webkit-backdrop-filter: blur(0);
                background-color: initial;
                left: 50%;
                transform: translateX(-50%);
                flex-direction: row;
                height: initial;
                width: initial;
                margin-left: auto;
            }

            .spacing::first-letter {
                margin-right: 5px;
            }
            .nav-link {
                position: relative;
                display: inline-block;
                color: #f5f5f5;
                font-size: 1.5rem;
                margin-block: .8em;

                @media (min-width: 640px) {
                    font-size: 1rem;
                    font-weight: 400 !important;
                    margin-inline: .8em;
                }

                &:not(:first-of-type){
                    margin-top: 1em;

                    @media(min-width:720px){
                        margin-top: initial;
                    }
                }

                &::after {
                    content: "";
                    display: block;
                    height: 1px;
                    background: #F5F5F5;
                    will-change: transform;
                    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.3, 0, 0, 1);
                    transition: -webkit-transform 0.5s cubic-bezier(0.3, 0, 0, 1);
                    transition: transform 0.5s cubic-bezier(0.3, 0, 0, 1);
                    transition: transform 0.5s cubic-bezier(0.3, 0, 0, 1),
                    -webkit-transform 0.5s cubic-bezier(0.3, 0, 0, 1);
                    -webkit-transform-origin: left center;
                    transform-origin: left center;
                    -webkit-transform-origin: right center;
                    transform-origin: right center;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                }

                &:hover::after,
                &:focus::after {
                    -webkit-transform-origin: left center;
                    transform-origin: left center;
                    -webkit-transform: none;
                    transform: none;
                }
            }
        }

        .nav-links-active {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100vh;
            color: #f5f5f5;
            font-size: 1.5rem;
            .nav-link {
                display: inline-block;
                margin-block: .5em;
                font-weight: bold;
            }
        }

        .mobile-nav-enter-from,
        .mobile-nav-leave-to {
            transform: translateY(-100%);
        }
        .mobile-nav-enter-active,
        .mobile-nav-leave-active {
            transition: .6s ease;
        }
        .logo {
            width: 50px;
            filter: drop-shadow(0px 1px 0px rgb(0 0 0 / .5));
            z-index: 2;

            @media (min-width: 640px) {
                width: 80px;
            }
        }

        .menu {
            display: block;
            cursor: pointer;
            z-index: 2;

            @media(min-width:1064px) {
                display: none;
            }

            div {
                width: 40px;
                height: 2px;
                border-radius: 2px;
                background-color: #fff;
            }
            .top-line,
            .bottom-line {
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
                transition: .3s ease all;
            }
            .top-line {
                margin-bottom: 10px;
            }
            .bottom-line {
                margin-top: 10px;
            }
        }
        .menu-active {
            .top-line {
                transform: rotate(-45deg);
                transform-origin: 70%;
            }
            .bottom-line {
                transform: rotate(45deg);
                transform-origin: 70%;
            }
        }
        .booking-btn {
            display: none;
            border: 1px solid #efefef;
            border-radius: 3px;

            color: #efefef;
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: .5px;
            text-transform: uppercase;

            margin-left: auto;
            padding: .75em 1.5em;
            position: absolute;

            bottom: -550px;
            left: 50%;
            transform: translateX(-50%);

            transition: .3s ease;

            &:hover {
                border-color: #C4743E;
            }

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                background-color: #C4743E;
                scale: 0;
                transition: inherit;
                z-index: -1;
            }

            &:hover::after {
                transition-delay: .2s;
                scale: 1;
            }

            @media(min-width: 1064px) {
                display: initial;
                position: relative;
                bottom: unset;
                left: unset;
                font-size: .8rem;
                transform: translateX(0);
            }
        }
    }
</style>