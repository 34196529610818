<template>
    <section class="not-found">
        <h1 class="not-found__title">Oops!</h1>
        <p class="not-found__subtitle">404 - cette page n'existe pas</p>
        <router-link to="/" class="cta">accueil</router-link>
    </section>
</template>

<style lang="scss" scoped>
    section {
        display: grid;
        place-content: center;
        height: 80vh;
        width: 100%;
        text-align: center;

        .not-found__title {
            font-size: clamp(5rem, 8vw, 10rem);
            font-weight: 800;
            background: url(../assets/img/hero-bg.jpg) 50% 100%;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            filter: drop-shadow(0px 5px 10px rgba(44, 62, 80, .5));
        }

        .not-found__subtitle {
            font-weight: 500;
            text-transform: uppercase;
        }

        .cta {

            @media (min-width:640px) {
                width: 16em;
                margin-inline: auto;
            }
        }
    }
</style>