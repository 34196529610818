<template>
    <section class="social">
        <img src="@/assets/img/instagram/insta1.jpg" alt="Manuela Nutrition">
        <a href="https://www.instagram.com/manuela.nutrition.paris/?igshid=YmMyMTA2M2Y%3D" class="instagram">
            <i class="fa-brands fa-instagram"></i>
            <div class="instagram-link">
                <span class="instagram-title">Rejoignez-moi sur</span>
                <img src="@/assets/img/insta-logo.png" class="instagram-logo" alt="Instagram">
            </div>
        </a>
        <img src="@/assets/img/instagram/insta2.jpg" alt="Manuela Nutrition">
        <img src="@/assets/img/instagram/insta3.jpg" alt="Manuela Nutrition">
        <img src="@/assets/img/instagram/insta4.jpg" alt="Manuela Nutrition">
    </section>
</template>

<script>
export default {
    name: 'SocialComponent',

    setup() {
        let contactDetails = {
            phone : '+33630616999',
            mail : 'daniel.sutic@darom.fr'
        }

        return { contactDetails }
    },
}
</script>

<style lang="scss" scoped>

.social {
    display: flex;
    flex-flow: row wrap;

    > * {
        flex: 1 1 15em;
        height: 250px;
    }
}
    .instagram {
        display: flex;
        flex-direction: column;
        padding: 1em;

        i {
            align-self: flex-end;
            color: #FE5100;
            font-size: 3em;
        }
        .instagram-link {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 3em;

            .instagram-title {
                color: #C4743E;
                font-family: WestivaMedium;
                font-size: 1.5rem;
                margin-bottom: 1em;
            }

            .instagram-logo {
                width: 120px;
            }
        }
    }
</style>