import { createRouter, createWebHistory } from 'vue-router'
import AccueilView from '@/views/AccueilView.vue'
import NonTrouve from '@/views/NonTrouve.vue'

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: AccueilView
  },
  {
    path: '/apropos',
    name: 'Apropos',
    component: () => import('@/views/AproposView.vue')
  },
  {
    path: '/naturopathie',
    name: 'Naturopathie',
    component: () => import('@/views/NaturopathieView.vue')
  },
  {
    path: '/nutrition',
    name: 'Nutrition',
    component: () => import('@/views/NutritionView.vue')
  },
  {
    path: '/minceur',
    name: 'Minceur',
    component: () => import('@/views/MinceurView.vue')
  },
  {
    path: '/soins',
    name: 'Soins',
    component: () => import('@/views/SoinsView.vue')
  },
  {
    path: '/detox',
    name: 'Detox',
    component: () => import('@/views/DetoxView.vue')
  },
  {
    path: '/mentions-legales',
    name: 'Mentions',
    component: () => import('@/views/MentionsView.vue')
  },
  {
    path: '/politique-de-confidentialite',
    name: 'Politique',
    component: () => import('@/views/MentionsView.vue')
  },
  { 
    path: '/:pathMatch(.*)*',
    name: 'NonTrouve',
    // component: () => import('../views/NonTrouve.vue')
    component: NonTrouve
  },
  {
    path: "/:catchAll(.*)",
    name: "NonTrouve",
    component: NonTrouve,
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(){
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  },
  routes
})

export default router
