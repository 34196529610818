<template>
    <div class="main-hero">
        <div class="main-hero-title">
            <h1 class="hero-title__title">
                {{ title }}
            </h1>
            <p class="hero-title__subtitle">
                {{ subTitle }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name : 'HeroComponent',
    props: ['title', 'subTitle'],

    setup() {
        
    },
}
</script>

<style lang="scss" scoped>
    .main-hero {
        display: flex;
        position: relative;

        .main-hero-title {
            color: #F5F5F5;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            padding: 1.5em;
            text-align: center;
            .hero-title__title {
                font-family: WestivaBold;
                font-size: clamp(3rem, 8vw, 6rem);
                font-weight: bold;

                @media (min-width: 640px) {
                    letter-spacing: 1.5px;
                    line-height: 1.4;
                }
            }
            .hero-title__subtitle {
                font-size: clamp(1.1rem, 2vw, 1.5rem);
                font-weight: 300;
            }
        }
    }
</style>