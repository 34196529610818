<template>
    <footer>
        <div class="footer-container">
            <div class="footer-container__logo">
                <router-link to="/">
                    <img src="@/assets/logo-footer.png" alt="Manuela Nutrition">
                </router-link>
            </div>
            <div class="footer-container__details">
                <div>
                    <h5 class="secondary-title">Adresse</h5>
                    <p>{{fullAddress}}</p>
                    <div>
                        <a :href="`mailto:${ contactDetails.mail }`" class="contact-details__icons contact-details__mail">
                            <img src="@/assets/icons/mail.png" alt="Contact Manuela">
                        </a>
                        <a :href="`${ contactDetails.instagram }`" class="contact-details__icons contact-details__instagram">
                            <img src="@/assets/icons/instagram.png" alt="Contact Manuela">
                        </a>
                    </div>
                </div>
                <div>
                    <h5 class="secondary-title">Horaires</h5>
                    <ul>
                        <li>Lundi: 15h - 21h</li>
                        <li>Mardi - Vendredi: sur RDV</li>
                        <li>Jeudi: 8h - 18h</li>
                        <li>Samedi - Dimanche: sur RDV</li>
                        <li>Mercredi: Fermé</li>
                    </ul>
                </div>
                <div>
                    <h5 class="secondary-title">Liens</h5>
                    <ul>
                        <li><router-link to="/apropos">À propos</router-link></li>
                        <li><router-link to="/detox">Détox</router-link></li>
                        <li><router-link to="/minceur">Minceur</router-link></li>
                        <li><router-link to="/naturopathie">Naturopathie</router-link></li>
                        <li><router-link to="/nutrition">Nutrition</router-link></li>
                        <li><router-link to="/soins">Soins</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-legal">
            <div class="copyright">
                <span>&copy; {{ year }} Manuela Nutrition Paris</span>
            </div>
            <ul class="legal-links">
                <li class="legal-links__link">
                    <router-link to="/mentions-legales">Mentions Legales</router-link>
                </li>
                <li class="legal-links__link">
                    <router-link to="/politique-de-confidentialite">Politique de Confidéntialite</router-link>
                </li>
            </ul>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',

    setup() {
        let contactDetails = {
            phone : '+33188615773',
            mail : 'contact@manuelanutritionparis.com',
            instagram : 'https://www.instagram.com/manuela.nutrition.paris/?igshid=YmMyMTA2M2Y%3D'
        }

        let address = {
            number : 21,
            street : `rue d'Argenteuil`,
            city : 'Paris',
            postalCode : 75001
        }

        let openingHours = {
            monday : '15h - 21h',
            tuesday : '8h - 21h',
            wednesday : 'Fermé',
            thursday : '8h - 14h',
            friday : '18h - 21h',
            saturday : '8h - 12h',
            sunday : 'Fermé',
        }

        let year = new Date().getFullYear()

        let fullAddress = address.number + ' ' + address.street + ', ' + address.city + ' ' + address.postalCode

        return { contactDetails, address, year, fullAddress, openingHours }
    }
}
</script>

<style lang="scss" scoped>
    footer {
        .footer-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 3em 2em;

            @media (min-width:780px) {
                flex-direction: row;
                justify-content: space-between;
                padding-block: 5em;
            }

            .footer-container__logo {
                width: 150px;
                height: 100%;
                margin-bottom: 3em;
            }

            .footer-container__details {
                display: flex;
                flex-flow: row wrap;

                > * {
                    flex: 1 1 5em;
                }
                div:not(:first-of-type){
                    @media(min-width:640px) {
                        margin-left: 5em;
                        margin-top: 0;
                    }
                       
                }
                p {
                    font-size: 1rem;
                }

                li {
                    margin-block: .4em;
                }

            }
        }

        .copyright-legal {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            border-top: 1px solid rgb(106, 106, 106);

            font-size: .8rem;
            font-weight: 300;
            width: 100%;

            padding: 2em;

            @media (min-width: 640px) {
                justify-content: space-between;
            }

            .copyright{
                margin-bottom: 1em;

                @media (min-width: 640px) {
                    margin-bottom: 0;
                }
            }
            .legal-links {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-evenly;

                @media (min-width: 640px) {
                    margin-bottom: 0;
                }

                .legal-links__link {
                    transition: .3s ease;
                    margin-block: .5em;

                    @media (min-width: 640px) {
                        margin-block: 0;
                    }

                    &:not(:first-of-type){
                        margin-left: 2em;
                    }
                    &:hover {
                        color: #C4743E;
                    }
                }
            }
        }


    }


</style>