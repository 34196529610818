<template>
	<!-- <router-view v-slot="{ Component }">
		<transition name="fade" mode="out-in">
			<component :is="Component" />
		</transition>
	</router-view> -->
	<router-view />
	<div class="booking-btn-mobile">
		<a href="https://www.planity.com/manuela-cardos-naturopathe-et-masseuse-bien-etre-75001-paris">
            réservation
        </a>
	</div>
	<aside>
		<Social />
	</aside>
	<Footer />
</template>

<script>

import Social from '@/components/Social.vue';
import Footer from '@/components/Footer.vue';

export default {

  components: {
    Social,
    Footer,
  }
}
</script>

<style lang="scss">

@import "@/style/general.scss";
@import "@/style/fonts.scss";
#app {
	position: relative;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity .5s ease-out;;
}

.booking-btn-mobile {
	position: fixed;
	bottom: 5%;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;

	color: #efefef;
	background-color: #C4743E;
	border-radius: 3px;
    font-size: 1rem;
    font-weight: 400;
	padding: .65em 1em;
    text-transform: uppercase;
	z-index: 1;

	@media(min-width:940px) {
		display: none;
	}
}

</style>
