<template>
    <section>
        <div class="intro">
            <div class="intro-description">
                <h3 class="main-title">À propos</h3>
                <p class="main-subtitle">
                    Diplômée en nutrition, naturopathie spécialisée en phytothérapie, micro nutrition et aromathérapie
                </p>
                <p class="intro-description__text">
                    Je vous aide à trouver un bon equilibre et à maintenir la bonne vitalité de votre organisme, qui contribuent à votre beauté extérieure.
                </p>
                <router-link :to="{ name: 'Apropos' }" class="cta-large">
                        <span class="text">découvrir</span>
                        <span class="arrow"></span>
                </router-link>
            </div>
            <div class="intro-images">
                <img src="@/assets/img/intro.jpeg" class="intro-images__main" alt="Nutrition">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'IntroComponent',
    setup() {
        
    },
}
</script>

<style lang="scss" scoped>
    .intro {
        display: flex;
        align-items: center;
        position: relative;
        .intro-description {
            font-weight: 200;
            position: absolute;
            top: 20px;
            padding: 1em;
            z-index: 1;
            @media (min-width:640px) {
                width: 50%;
                position: relative;
                padding-inline: 3em;
            }
            .intro-description__text {
                font-weight: 400;
                margin-block: 2em;
                text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
            }
        }

        .intro-images {
            height: 850px;
            width: 50%;
            margin-left: auto;
            @media (min-width:640px) {
                height: 100vh;
                position: relative;
            }
            .intro-images__main {
                filter: blur(7px);
                @media (min-width: 640px) {
                    filter: blur(0) drop-shadow(0 8px 24px rgb(0 0 0 / .3));
                }
            }
        }
    }
</style>