<template>
	<header>
		<Navbar />
		<Hero class="mainHero" :title="title" :subTitle="subTitle" />
	</header>

	<main>
		<Intro />
		<Works />
		<Map />
	</main>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Hero from '@/components/Hero.vue'
import Intro from '@/components/Intro.vue'
import Works from '@/components/Works.vue'
import Map from '@/components/Map.vue'

export default {
  name: 'AccueilView',
  components: {
    Navbar,
    Hero,
    Intro,
    Works,
    Map
  },
  setup() {
    let title = 'Bonjour!';
    let subTitle = `Bienvenue entre les mains expertes et dans le “sur mesure” de Manuela.`

    return { title, subTitle }
  }
}
</script>

<style lang="scss" scoped>

.mainHero {
    height: 100vh;
    background:linear-gradient(rgb(0 0 0 / .5), rgb(0 0 0 / 0)), url("@/assets/img/hero-bg.jpg") no-repeat center/cover;
}
</style>
