<template>
    <section>
        <div class="soins">
            <div class="soins-description">
                <h3 class="main-title">Expertise</h3>
            </div>

            <div class="soins-container">
                <router-link to="/detox" class="soin">
                    <div class="soin-image">
                            <img src="@/assets/img/juice.jpg" alt="Detox">
                        </div>
                        <div class="soin-description">
                            <h4 class="secondary-title">Détox</h4>
                    </div>
                </router-link>

                <router-link to="/naturopathie" class="soin">
                    <div class="soin-image">
                            <img src="@/assets/img/naturopathie.jpg" alt="Naturopathie">
                        </div>
                        <div class="soin-description">
                            <h4 class="secondary-title">Naturopathie</h4>
                    </div>
                </router-link>

                <router-link to="/nutrition" class="soin">
                    <div class="soin-image">
                            <img src="@/assets/img/hero-bg/nutrition-hero.jpg" alt="Nutrition">
                        </div>
                        <div class="soin-description">
                            <h4 class="secondary-title">Nutrition</h4>
                    </div>
                </router-link>

                <router-link to="/minceur" class="soin">
                    <div class="soin-image">
                            <img src="@/assets/img/minceur5.jpeg" alt="Minceur">
                        </div>
                        <div class="soin-description">
                            <h4 class="secondary-title">Minceur</h4>
                    </div>
                </router-link>

                <router-link to="/soins" class="soin">
                    <div class="soin-image">
                            <img src="@/assets/img/soins.jpeg" alt="Maderotherapie">
                        </div>
                        <div class="soin-description">
                            <h4 class="secondary-title">Soins</h4>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WorksComponent',
    setup() {
        
    },
}
</script>

<style lang="scss" scoped>
.soins {
    position: relative;
    margin-block: 5em;

    .soins-description {
        padding: 1em;
        margin-bottom: 2em;

        @media(min-width: 640px) {
            padding-inline: 3em;
        }

        .main-title {
            margin-bottom: .5em;
        }
    }
    .soins-container {
        display: flex;
        flex-flow: row wrap;

        > * {
            flex: 1 1 17em;
        }
        .soin {
            position: relative;
            transition: .2s ease-in-out;
            .soin-image {
                height: 400px;
                position: relative;

                &::after{
                    content: '';
                    height: 100%;
                    width: 100%;
                    background: rgba(0, 0, 0, 0.1);
                    position: absolute;
                    inset: 0;
                    display: block;
                }

                @media (min-width:640px) {
                    height: 600px;
                }
            }
            .soin-description {
                position: absolute;
                top: 7%;
                right: 10%;
            }
            &:hover {
                flex-grow: 5;
            }
        }
    }
}

.secondary-title {
    color: #efefef;
    font-size: 2rem;
    line-height: 0;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    filter: drop-shadow(0 4px 12px rgb(0 0 0 / .9));
}
</style>